export const trackPageview = (url) => {
  try {
    window.gtag('config', 'G-NGG4PZH65C', { page_path: url });
  } catch (error) {
    console.error('Error tracking pageview:', error);
  }
};
  
export const trackEvent = (action, params) => {
  try {
    window.gtag('event', action, params);
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};
