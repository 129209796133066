import React from 'react';
import { LoadScript, GoogleMap as GoogleMapAPI, Marker, InfoWindow } from '@react-google-maps/api';


const containerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: 46.514209,
    lng: 6.499870
};


const GoogleMap: React.FC = () => {
    const [infoWindowVisible, setInfoWindowVisible] = React.useState(true);

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyAMbqu7YvkzmBoS_atUmfWWY4t3Kwr-pxI"
        >
            <GoogleMapAPI
                mapContainerStyle={containerStyle}
                center={center}
                zoom={16}
            >
                <Marker 
                position={center} 
                onClick={() => setInfoWindowVisible(true)}  // Optional: show InfoWindow on marker click
                />
                {infoWindowVisible && (
                <InfoWindow
                    position={center}
                    onCloseClick={() => setInfoWindowVisible(false)}
                >
                    <div>
                    Avenue des Pâquis 29, 1110 Morges
                    </div>
                </InfoWindow>
                )}
            </GoogleMapAPI>
        </LoadScript>
    );
}

export default GoogleMap;
