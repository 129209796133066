import React from 'react';
import { Box } from '@mui/material';

interface SectionSeparatorProps {
  height: string;
}

const SectionSeparator: React.FC<SectionSeparatorProps> = ({ height }) => {
  return (
    <Box sx={{
      width: '100%',
      minHeight: height,
      backgroundColor: 'background.paper', 
    }} />
  );
}

export default SectionSeparator;
