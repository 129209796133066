import { useEffect, useState } from 'react';
import { fetchPriceListFromApi, fetchPriceWithPackListFromApi, Price } from '../utils/databaseApiUtils';

const useFetchPriceWithPackList = (categoryId: number) => {
  const [prices, setPrices] = useState<Price[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedPrices = await fetchPriceWithPackListFromApi(categoryId);
      if (fetchedPrices) {
        setPrices(fetchedPrices);
      } else {
        setError("Failed to fetch data");
      }
      setLoading(false);
    };

    fetchData();
  }, [categoryId]);

  return { prices, loading, error };
};

export default useFetchPriceWithPackList;
