import React, { useState, useRef } from 'react';
import { TextField, Button, Container, Alert } from '@mui/material';
import useAddMessage from '../../hooks/useAddMessage';

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
const phoneRegex = /^(\+41|0)[1-9\s][0-9\s]{1,12}$/;

const ContactForm: React.FC = () => {
  const { addNewMessage, success, error, loading } = useAddMessage();

  const [nameError, setNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const validateField = (name: string, value: string) => {
    switch (name) {
      case 'name':
        setNameError(value ? null : 'Nom est requis.');
        break;
      case 'email':
        setEmailError(emailRegex.test(value) ? null : 'Email invalide.');
        break;
      case 'phone':
        setPhoneError(phoneRegex.test(value) ? null : 'Téléphone invalide.');
        break;
      case 'messagetext':
        setMessageError(value ? null : 'Message est requis.');
        break;
      default:
        break;
    }
  };

  const handleValidation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    validateField(name, value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitted(true);

    // Get the current values of each field
    const formData = new FormData(event.currentTarget as HTMLFormElement);
    const formValues = Object.fromEntries(formData);

    // Validate each field
    for (const [name, value] of Object.entries(formValues)) {
      validateField(name, value as string);
    }

    // Check for errors after validating each field
    if (!nameError && !emailError && !phoneError && !messageError) {
      const messageData = formValues;
      await addNewMessage(messageData);

      if (success && formRef.current) {
        formRef.current.reset();
        setNameError(null);
        setEmailError(null);
        setPhoneError(null);
        setMessageError(null);
        setSubmitted(false);
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <form noValidate autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
        <TextField
          fullWidth
          margin="normal"
          label="Nom"
          variant="outlined"
          required
          name="name"
          error={Boolean(nameError)}
          helperText={nameError}
          onChange={handleValidation}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          variant="outlined"
          type="email"
          required
          name="email"
          error={Boolean(emailError)}
          helperText={emailError}
          onChange={handleValidation}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Téléphone"
          variant="outlined"
          type="tel"
          required
          name="phone"
          error={Boolean(phoneError)}
          helperText={phoneError}
          onChange={handleValidation}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Message"
          variant="outlined"
          multiline
          rows={4}
          required
          name="messagetext"
          error={Boolean(messageError)}
          helperText={messageError}
          onChange={handleValidation}
        />
        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          sx={{
            backgroundColor: '#D7A148',
            color: '#fff',
            marginTop: '16px',
            '&:hover': {
              backgroundColor: '#b58f4c',
            },
          }}
        >
          {loading ? 'Sending...' : 'Envoyez le message'}
        </Button>
      </form>
      {success && <Alert severity="success">Message sent successfully!</Alert>}
      {error && <Alert severity="error">Error sending message: {error.message}</Alert>}
    </Container>
  );
};

export default ContactForm;
