import React, { useState } from 'react';
import styles from './Footer.module.css';
import {
  TextField,
  Button,
  InputAdornment,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import {
  Email as EmailIcon,
  Place as AddressIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Send as SendIcon
} from '@mui/icons-material';

import useAddNewsletterMail from '../../hooks/useAddNewsletterMail';

const Footer: React.FC = () => {
  const [email, setEmail] = useState(''); // State for the email input
  const { addEmail, loading, error, success } = useAddNewsletterMail(); // Custom hook for email management
  const [open, setOpen] = useState(false); // State for success snackbar
  const [openError, setOpenError] = useState(false); // State for error snackbar
  const [isChecked, setChecked] = useState(false); // State for the checkbox
  const [openDialog, setOpenDialog] = useState<string | null>(null); //State for dialog

  // Email validation function
  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const isValidEmail = validateEmail(email);

  // Handle the subscribe button click
  const handleSubscribe = () => {
    if (isValidEmail && isChecked) { // Check if email is valid and checkbox is checked
      addEmail(email);
      setEmail('');
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    } else {
      setOpenError(true);
      setTimeout(() => {
        setOpenError(false);
      }, 5000);
    }
  };

  // Handle the snackbar close
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setOpenError(false);
  };

  // Handle the dialog close
  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  return (
    <footer className={styles.footer}>
      {/* Contact Information */}
      <div className={styles.container}>
      <div className={`${styles.row} ${styles.hideOnMobile}`}>
          <div className={styles.title}>Nous contacter</div>
          <div className={styles.iconRow}><EmailIcon /><span>contact@aurabeauty.ch</span></div>
          <div className={styles.iconRow}><AddressIcon /><span>Avenue des Pâquis 29, 1110 Morges</span></div>
        </div>
        {/* Social Media Information */}
        <div className={`${styles.row} ${styles.hideOnMobile}`}>
          <div className={styles.title}>Connectez avec nous</div>
          <div>Nous sommes désormais présents sur les réseaux sociaux. Suivez nous!</div><br />
          <div>
            <a href='https://www.facebook.com/aurabeautymorges' target='blank'><FacebookIcon /></a>
            <a href='https://www.instagram.com/aurabeautymorges/' target='blank'><InstagramIcon /></a>
          </div>
        </div>
        {/* Newsletter Subscription */}
        <div className={styles.row}>
          <div className={styles.title}>Rejoigner notre newsletter</div>
          <div>Abonnez-vous à notre newsletter pour recevoir les dernières nouvelles et offres exclusives. Pas de spam.</div><br />
          <div style={{ position: 'relative' }}>
            {/* Email Textfield */}
            <TextField
              label="Votre email"
              variant="outlined"
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubscribe}
                      size="small"
                      style={{ backgroundColor: '#D7A148' }}
                      disabled={loading || !isChecked} // Disable if loading or checkbox is not checked
                    >
                      <SendIcon style={{ color: 'white' }} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
           {/* Privacy Policy Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => setChecked(e.target.checked)}
                  sx={{
                    color: '#D7A148',  // default color
                    '&.Mui-checked': {
                      color: '#D7A148', // checked color
                    }
                  }}
                />
              }
              label={
                <span style={{
                  fontFamily: 'Josefin Sans',
                  fontSize: '16px',
                  fontWeight: '300'
                }}>
                  En envoyant ce message, j'accepte la Politique de Confidentialité et les Conditions Générales
                </span>
              }
              />
              {/* Snackbar for successful email addition */}
              <Snackbar 
                open={open && !!success}
                autoHideDuration={6000} 
                onClose={handleClose}
                style={{ position: 'absolute', bottom: '-50px', left: '0' }}
              >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Email ajouté avec succès !
                </Alert>
              </Snackbar>

              {/* Snackbar for error in email addition */}
              <Snackbar 
                open={open && !!error}
                autoHideDuration={6000} 
                onClose={handleClose}
                style={{ position: 'absolute', bottom: '-50px', left: '0' }}
              >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  {error?.message || "Une erreur s'est produite"}
                </Alert>
              </Snackbar>

              {/* Snackbar for loading state */}
              <Snackbar 
                open={open && loading}
                style={{ position: 'absolute', bottom: '-50px', left: '0' }}
              >
                <Alert severity="info" sx={{ width: '100%' }}>
                  Chargement...
                </Alert>
              </Snackbar>

              {/* Snackbar for invalid email format */}
              <Snackbar 
                open={openError}
                autoHideDuration={6000} 
                onClose={handleClose}
                style={{ position: 'absolute', bottom: '-50px', left: '0' }}
              >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  Adresse e-mail invalide !
                </Alert>
              </Snackbar>
          </div>
        </div>

        </div>
          {/* New Copyright Row */}
          <div className={styles.copyrightRow}>
            <div className={styles.copyright}>
              © AuraBeauty 2023. Tous Droits Réservés.
            </div>
        </div>

        {/* Legal Links */}
        <div className={styles.legalRow}>
          <div onClick={() => setOpenDialog('privacy')} className={styles.legalLeft}>Politique de confidentialité</div>
          <div onClick={() => setOpenDialog('terms')} className={styles.legalRight}>Conditions générales</div>
        </div>

        {/* Dialogs */}
        <Dialog
          open={openDialog === 'privacy'}
          onClose={handleCloseDialog}
        >
          <DialogTitle>{"Politique de confidentialité"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {/* Your privacy policy text */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDialog === 'terms'}
          onClose={handleCloseDialog}
        >
          <DialogTitle>{"Conditions générales"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {/* Your terms and conditions text */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
    </footer>
  );
}

export default Footer;
