import React from 'react';
import styles from './About.module.css';
import Header from '../../components/Header/Header'
import LayoutTwoCol from '../../components/LayoutTwoCol/LayoutTwoCol';
import SectionText from '../../components/SectionText/SectionText';
import SectionSeparator from '../../components/SectionSeparator/SectionSeparator';
import Footer from '../../components/Footer/Footer';
import LayoutOneCol from '../../components/LayoutOneCol/LayoutOneCol';
import GoogleMap from '../../components/GoogleMap/GoogleMap';

const About: React.FC = () => {
  return (
    <>
      <Header opacity={1}></Header>
      <SectionSeparator height='50px' />
      <LayoutTwoCol 
        id='layout-presentation'
        width='xl'
        leftGridSpan={4}
        leftContent={
          <img src="/crina-draghiciu-1-transparent.png" alt="Doctoresse DRAGHICIU" className={styles.image} />
        }
        rightContent={
          <SectionText 
            id='crina-draghiciu'
            color='gold'
            title='Doctoresse Crina DRAGHICIU'
            language='fr'
          />
        }
      />
      <SectionSeparator height='30px' />
      <LayoutOneCol 
        id='google-maps'
        width='xl'
        content={
          <GoogleMap />
        }
      />
      <Footer />
    </>
  );
}

export default About;
