import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SectionText from '../../components/SectionText/SectionText';
import styles from './Services.module.css'
import PriceList from '../../components/PriceList/PriceList';
import LayoutTwoCol from '../../components/LayoutTwoCol/LayoutTwoCol';
import SectionSeparator from '../../components/SectionSeparator/SectionSeparator';
import PriceListWithPack from '../../components/PriceListWithPack/PriceListWithPack';
import LayoutOneCol from '../../components/LayoutOneCol/LayoutOneCol';
import OneDocWidget from '../../components/OneDocWidget/OneDocWifget';

const Services: React.FC = () => {
  return (
    < >
      <Header opacity={1} />   
      <SectionSeparator height='50px'></SectionSeparator>
      <LayoutTwoCol
        id='layout-acyde-hyaluronique'
        width='xl'
        leftGridSpan={9}
        leftContent={
          <SectionText 
            id='acyde-hyaluronique'
            title='Acide Hyaluronique : La Clé pour une Peau Jeune et Revitalisée' 
            language='fr'
            color='gold'>
          </SectionText>
        }
        rightContent={
          <PriceList categoryId={1} />
        }
      > 
      </LayoutTwoCol>
      <SectionSeparator height='30px'></SectionSeparator>
      <LayoutTwoCol
        id='layout-skin-boosters'
        width='xl'
        leftGridSpan={9}
        leftContent={
          <SectionText 
            id='skin-boosters'
            title="Skin Boosters : Un Éclat Naturel pour une Peau Visiblement Rajeunie"
            language='fr'
            color='gold'>
          </SectionText>
        }
        rightContent={
          <PriceList categoryId={2} />
        }
      > 
      </LayoutTwoCol>
      <SectionSeparator height='30px'></SectionSeparator>
      <LayoutTwoCol
        id='layout-botox'
        width='xl'
        leftGridSpan={9}
        leftContent={
          <SectionText 
            id='botox'
            title="Injections de Botox : Votre Solution Rapide et Efficace contre les Signes de l'Âge"
            language='fr'
            color='gold'>
          </SectionText>
        }
        rightContent={
          <PriceList categoryId={3} />
        }
      > 
      </LayoutTwoCol>
      <SectionSeparator height='30px'></SectionSeparator>
      <LayoutTwoCol 
        id='layout-prp-dermapen'
        width='xl'
        leftGridSpan={6}
        leftContent={
          <SectionText 
            id='prp'
            title="PRP : Le Pouvoir de la Régénération Naturelle"
            language='fr'
            color='gold'>
          </SectionText>
        }
        rightContent={ 
          <PriceListWithPack categoryId={6} />
        }
      >
      </LayoutTwoCol>
      <SectionSeparator height='30px'></SectionSeparator>
      <LayoutTwoCol 
        id='layout-prp-dermapen'
        width='xl'
        leftGridSpan={6}
        leftContent={
          <SectionText 
          id='dermapen'
          title="Dermapen : Microneedling pour une Peau Sublime"
          language='fr'
          color='gold'>
        </SectionText>
        }
        rightContent={ 
          <PriceListWithPack categoryId={7} />
        }
      >
      </LayoutTwoCol>
      <SectionSeparator height='50px'></SectionSeparator>
      <LayoutOneCol 
          id='rendez-vous'
          align='left'
          width='md'
          content= {
            <SectionText 
              id='rdv'
              color='black'
              title='Rendez-Vous'
              language='fr'
            />
          }
      /> 
      <LayoutOneCol
        id='onedocwidget'
        width='md'
        align='center'
        content={
            <OneDocWidget />
        }
      >
      </LayoutOneCol>
      <Footer></Footer>  
    </>
  );
}

export default Services;
