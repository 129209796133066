import { useState } from 'react';
import { addMessage, Message } from '../utils/databaseApiUtils';

const useAddMessage = () => {
  const [message, setMessage] = useState<Message | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const addNewMessage = async (newMessage: Message) => {
    setLoading(true);
    try {
      // Perform API call to add the message
      const response = await addMessage(newMessage);
      if (response) {
        setMessage(response);  // Assuming addMessage returns the added message
        setSuccess(true);
      } else {
        // Handle the case where response is null or undefined
        setError(new Error("Failed to add message"));
        setSuccess(false);
      }
    } catch (e: any) {
      setError(new Error(e?.message || "An error occurred"));
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return { message, setMessage, success, error, loading, addNewMessage };
};

export default useAddMessage;
