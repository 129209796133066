import axios from 'axios';

// PROD
const API_BASE_URL = 'https://api.aurabeauty.ch/api';

// DEV
// const API_BASE_URL = 'http://localhost:5081/api';

interface ContentResponse {
  text: string;
}

export interface Price {
  categoryName: string;
  categoryDescription: string;
  serviceName: string;
  price: string;
  pack: string
  disabled: any;
}

export interface NewsletterMailList {
  mailAddress?: string;
}

export interface Message {
  name?: string;
  email?: string;
  phone?: string;
  messageText?: string;
}

// Call the API to get content text
export const fetchContentTextFromApi = async (language: string, sectionId: string): Promise<ContentResponse | null> => {
  try {
    const apiUrl = `${API_BASE_URL}/content?language=${language}&sectionId=${sectionId}`;
    const response = await axios.get<ContentResponse>(apiUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching content:', error);
    return null;
  }
};

// Call the price list by category
export const fetchPriceListFromApi = async (categoryId: number): Promise<Price[] | null> => {
  try {
    const apiUrl = `${API_BASE_URL}/services/GetPricesbyCategory/${categoryId}`;
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data: Price[] = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching price list:', error);
    return null;
  }
};

// Call the price with pack list by category
export const fetchPriceWithPackListFromApi = async (categoryId: number): Promise<Price[] | null> => {
  try {
    const apiUrl = `${API_BASE_URL}/services/GetPricesWithPackByCategory/${categoryId}`;
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data: Price[] = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching price list:', error);
    return null;
  }
};

// Call the API to add a new email address to the newsletter list
export const addNewsletterMail = async (mailAddress: string): Promise<NewsletterMailList | null> => {
  try {
    const apiUrl = `${API_BASE_URL}/NewsletterMailList/add`;
    const response = await axios.post<NewsletterMailList>(apiUrl, {
      mailAddress,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding email address:', error);
    return null;
  }
};

// Call the API to add message in the database 
export const addMessage = async (message: Message): Promise<Message | null> => {
  try {
    const apiUrl = `${API_BASE_URL}/Messages/add`;
    const response = await axios.post<Message>(apiUrl, message);
    return response.data;
  } catch (error) {
    console.error('Error adding message:', error);
    return null;
  }
};
