import React from 'react';
import styles from './AuraBeautyTitle.module.css';

const AuraBeautyTitle = () => {
  return (
    <div className="container">
      <div className={styles.aurabeauty}>Aura Beauty</div>
      <div className={styles.luxuryesthetics}>Luxury Esthetics</div>
    </div>
  );
};

export default AuraBeautyTitle;
