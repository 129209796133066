// useGA.ts
import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageview } from '../../analytics';


const PageTracking: React.FC = () => {
  const location = useLocation();

    useEffect(() => {
        trackPageview(location.pathname + location.search);
    }, [location]);

  return null;
};

export default PageTracking;
