import { useEffect, useState } from 'react';
import { fetchContentTextFromApi } from '../utils/databaseApiUtils';

const useFetchContent = (language: string, sectionId: string) => {
  const [text, setText] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const content = await fetchContentTextFromApi(language, sectionId);
      if (content) {
        setText(content.text);
      }
      setLoading(false);
    };

    fetchData();
  }, [language, sectionId]);

  return { text, loading };
};

export default useFetchContent;
