// LayoutTwoCol.tsx
import React, { ReactNode } from 'react';
import { Container, Grid } from '@mui/material';

interface LayoutTwoColProps {
  id: string;
  leftContent: ReactNode;
  rightContent: ReactNode;
  width?: 'xs' | 'sm' | 'md' |'lg' | 'xl' ;
  leftGridSpan?: number;
}

const LayoutTwoCol: React.FC<LayoutTwoColProps> = ({ id, leftContent, rightContent, width='xl', leftGridSpan }) => {
  const leftGridSize = leftGridSpan ? leftGridSpan : 6;
  const rightGridSize = 12 - Number(leftGridSize);

  return (
    <Container id={id} maxWidth={width}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={leftGridSize}>
            {leftContent}
        </Grid>
        <Grid item xs={12} lg={rightGridSize} 
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', 
                '@media (min-width: 1280px)': {  // Starting from 'lg' and above
                  justifyContent: 'flex-end'  
                },
              }}
        >
          {rightContent}
        </Grid>
      </Grid>
    </Container>
  );
}

export default LayoutTwoCol;
