import React, { useEffect } from 'react';

const OneDocWidget: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      var widgetConfigs = widgetConfigs || {};
      widgetConfigs["21748670882280ab5abce4daeeebb44693c98d67494deedca5d583304d08265b"] = {};
      widgetConfigs["21748670882280ab5abce4daeeebb44693c98d67494deedca5d583304d08265b"].measurementIds = [];
      widgetConfigs["21748670882280ab5abce4daeeebb44693c98d67494deedca5d583304d08265b"].hasGoogleTagTracking = !1;
      window.addEventListener("message", function(e) {
        var t = e.data["od-widget-id"],
            i = e.data["od-widget-height"],
            d = e.data["od-widget-ios"];
        if (t && t in widgetConfigs && "21748670882280ab5abce4daeeebb44693c98d67494deedca5d583304d08265b" === t) {
          var a = document.getElementById("od-widget-" + t);
          i && (a.style.height = i + "px");
          !0 === d && (a.style.width = "100px", a.style["min-width"] = "100%", a.scrolling = "no");
          var g = e.data["od-tracking-configs"];
          void 0 !== g && (widgetConfigs[t].measurementIds = g);
          var o = e.data["od-has-google-tag-manager"];
          void 0 !== o && (widgetConfigs[t].hasGoogleTagTracking = o);
          var n = e.data.GA4;
          if (widgetConfigs[t].hasGoogleTagTracking && window.dataLayer && n) {
            var s = Object.keys(n.properties),
                r = { event: n.name };
            s.forEach(function(e) { r[e] = n.properties[e] });
            dataLayer.push(r);
          }
          if (n && void 0 !== window.gtag) {
            for (var w = 0; w < widgetConfigs[t].measurementIds.length; w++) {
              var f = widgetConfigs[t].measurementIds[w],
                  c = (s = Object.keys(n.properties), { send_to: f });
              s.forEach(function(e) { c[e] = n.properties[e] });
              gtag("event", n.name, c);
            }
          }
        }
      });
      window.addEventListener("load", function(e) {
        for (var t = document.querySelectorAll("iframe.od-widget"), i = 0; i < t.length; i++) {
            var d = t[i];
            d.dataset && d.dataset.src && (d.src = d.dataset.src);
        }
      });
      
    `;
    document.body.appendChild(script);

      // Cleanup on unmount
      return () => {
        document.body.removeChild(script);
  
        // Remove any additional event listeners, if necessary
      };
  }, []);

  return (

      <iframe 
          className="od-widget" 
          id="od-widget-21748670882280ab5abce4daeeebb44693c98d67494deedca5d583304d08265b" 
          src="https://www.onedoc.ch/fr/widget/21748670882280ab5abce4daeeebb44693c98d67494deedca5d583304d08265b" 
          frameBorder={0}
          style={{ width: '100%', maxWidth: '1024px', height: '400px' }}

      />
 
  );

};

export default OneDocWidget;
