import React from 'react';
import SectionText from '../../components/SectionText/SectionText';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SectionSeparator from '../../components/SectionSeparator/SectionSeparator';
import OneDocWidget from '../../components/OneDocWidget/OneDocWifget';
import SectionCover from '../../components/SectionCover/SectionCover';
import LayoutOneCol from '../../components/LayoutOneCol/LayoutOneCol';

const Home: React.FC = () => {
  return (
    <>
      <Header />
      <SectionCover />
      <div>
          <SectionSeparator height='70px'></SectionSeparator>
          <LayoutOneCol
            id='introduction'
            width='lg'
            align='justify'
            content={
              <SectionText 
                  id='aura-beauty-description'
                  color='gold'
                  title='Aura Beauty' 
                  language='fr' 
                >
              </SectionText>
            }
            >
          </LayoutOneCol>
        <SectionSeparator height='50px'></SectionSeparator>
        <LayoutOneCol 
            id='rendez-vous'
            align='justify'
            width='md'
            content= {
              <SectionText 
                id='rdv'
                color='black'
                title='Rendez-Vous'
                language='fr'
              />
            }
        /> 
        <LayoutOneCol
          id='onedocwidget'
          width='md'
          align='center'
          content={
              <OneDocWidget />
          }
        >
        </LayoutOneCol>
        <SectionSeparator height='50px'></SectionSeparator>
      </div>
      <Footer />
    </>
  );
}

export default Home;
