import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import { IconButton, Drawer, List, ListItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import AuraBeautyTitle from '../AuraBeatyTitle/AuraBeautyTitle';

interface HeaderProps {
  opacity?: number;  
}

const Header: React.FC<HeaderProps> = ({ opacity = 0.7 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const routes = [
    { name: 'Accueil', path: '/' },
    { name: 'Services', path: '/services' },
    { name: 'À propos', path: '/about' },
    { name: 'Contact', path: '/contact' },
  ];

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  

  const headerStyle = {
    backgroundColor: `rgba(0, 0, 0, ${opacity})`,
  };

  return (
    <header className={isMobile ? styles.stickyHeader : styles.normalHeader} style={headerStyle}>
       <div className={styles.logoContainer}>
        <img src="/aura-beauty-logo-transparent-xs.png" alt="Aura Beauty" className={styles.logo} />
        {!isMobile && <AuraBeautyTitle />}
      </div>
      <nav>
        {isMobile ? (
          <div>
            <IconButton 
              edge="start" 
              color="primary" 
              aria-label="menu" 
              onClick={toggleDrawer(true)}
              sx={{ color: theme => theme.palette.primary.main }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              sx={{ 
                '.MuiDrawer-paper': {
                  backgroundColor: theme => theme.palette.primary.main,
                  color: theme => theme.palette.primary.contrastText,
                }
              }}
            >
              <List>
                {routes.map((route, index) => (
                  <ListItem 
                    button 
                    key={route.name} 
                    onClick={toggleDrawer(false)}
                    sx={{ 
                      '&:hover': {
                        backgroundColor: theme => theme.palette.primary.dark,
                      }
                    }}
                  >
                    <Link to={route.path} className={styles.menuLink}>{route.name}</Link>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </div>
        ) : (
          <ul className={styles.menu}>
            <li><Link to="/">Accueil</Link></li> 
            <li><Link to="/services">Services</Link></li> 
            <li><Link to="/about">À propos</Link></li> 
            <li><Link to="/contact">Contact</Link></li> 
          </ul>
        )}
      </nav>
    </header>
  );
}

export default Header;
