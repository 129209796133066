import React, { useEffect } from 'react';
import styles from './SectionCover.module.css';
import PromotionMessage from '../PromotionMessage/PromotionMessage';

const SectionCover: React.FC = () => {
  useEffect(() => {
    const handleScroll = () => {
      const container = document.getElementById('parallax-container');
      if (container) {
        const scrollPosition = window.scrollY;
        container.style.backgroundPositionY = `${scrollPosition * 0.5}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="parallax-container" className={styles['parallax-container']} >
      {/* <PromotionMessage /> */}
    </div> 
  );
};

export default SectionCover;
