import React from 'react';
import { Button, CircularProgress, Paper, List, ListItem, Typography, Alert } from '@mui/material';
import useFetchPriceWithPackList from '../../hooks/useFetchPriceWithPackList';
import { useTheme } from '@mui/material/styles';

interface PriceListWithPackProps {
  categoryId: number;
}

const PriceListWithPack: React.FC<PriceListWithPackProps> = ({ categoryId }) => {
  const { prices, loading, error } = useFetchPriceWithPackList(categoryId);
  const theme = useTheme();

  if (loading) return <CircularProgress style={{ color: '#D7A148' }} />;
  if (error) return <Alert severity="error">Error: {error}</Alert>;

  return (
    <Paper elevation={2} 
        sx={{
          width: '100%',
          padding: '16px',
          margin: '16px 0',
          borderRadius: '8px',
          backgroundColor: '#fff',
          maxWidth: '650px',
          transition: 'box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out',  // Add transition
          '&:hover': {
            boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12), 0 7px 10px -5px rgba(0,0,0,0.15)',  // Add shadow on hover
            // backgroundColor: theme.palette.grey[100],  // Optionally change background color on hover
          }
    }}>
      <List>
        {prices.map((price, index) => (
          <ListItem key={index} style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 0',
          }}>
              <Typography variant="body1" style={{ textAlign: 'left', fontFamily: theme.typography.fontFamily}}>{price.serviceName}</Typography>
              <Typography variant="body1" style={{ textAlign: 'right', whiteSpace: 'nowrap', fontFamily: theme.typography.fontFamily }}>{price.price}</Typography>
              <Typography variant="body1" style={{ textAlign: 'right', fontFamily: theme.typography.fontFamily}}>{price.pack}</Typography>
          </ListItem>
        ))}
      </List>
      <div style={{ textAlign: 'center', marginTop: '16px' }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#D7A148', color: '#fff' }}
          href="#rendez-vous"
        >
          Prenez un rendez-vous
        </Button>
      </div>
    </Paper>
  );
};

export default PriceListWithPack;
