import React from 'react';
import { Typography, CircularProgress, Box } from '@mui/material';
import useFetchContent from '../../hooks/useFetchContent'; 
import { useTheme } from '@mui/material/styles';

interface Props {
  id: string;
  title: string;
  color: 'black' | 'gold';
  language: string;
}

const SectionText: React.FC<Props> = ({ id, title, color, language }) => {
  const { text, loading } = useFetchContent(language, id);
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h3"  // Adjust variant as needed
        sx={{
          fontSize: '40px',
          fontWeight: 300,
          borderBottom: '1px solid #acacac',
          marginBottom: '20px',
          paddingBottom: '10px',
          color: color === 'gold' ? '#D7A148' : '#000',
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          marginTop: '20px',
          textAlign: 'justify',
          lineHeight: '28px',
        }}
      >
        {
          !loading ? 
            <div dangerouslySetInnerHTML={{ __html: text || '' }} /> :
            <CircularProgress style={{ color: '#D7A148' }} />
        }
      </Box>
    </Box>
  );
};

export default SectionText;
