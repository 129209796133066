import { useEffect, useState } from 'react';
import { fetchPriceListFromApi, Price } from '../utils/databaseApiUtils';

const useFetchPriceList = (categoryId: number) => {
  const [prices, setPrices] = useState<Price[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedPrices = await fetchPriceListFromApi(categoryId);
      if (fetchedPrices) {
        setPrices(fetchedPrices);
      } else {
        setError("Failed to fetch data");
      }
      setLoading(false);
    };

    fetchData();
  }, [categoryId]);

  return { prices, loading, error };
};

export default useFetchPriceList;
