// LayoutOneCol.tsx
import React, { ReactNode, CSSProperties } from 'react';
import { Container, Grid } from '@mui/material';
import styles from './LayoutOneCol.module.css';

interface LayoutOneColProps {
    id: string;
    content: ReactNode;
    width?: 'xs' | 'sm' | 'md' |'lg' | 'xl' ;
    align?: 'left' | 'right' | 'center' | 'justify';
}

const LayoutOneCol: React.FC<LayoutOneColProps> = ({ id, content, width = 'xl', align }) => {
  // Style for the container
  const containerStyle: CSSProperties = {
    ...(align ? { textAlign: align } : {}),
  };

  return (
    <Container id={id} maxWidth={width} style={containerStyle}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <article className={`${styles.column}`}>
            {content}
          </article>
        </Grid>
      </Grid>
    </Container>
  );
}

export default LayoutOneCol;
