import { useState } from 'react';
import { addNewsletterMail } from '../utils/databaseApiUtils';

const useAddNewsletterMail = () => {
  const [email, setEmail] = useState<string>('');
  const [success, setSuccess] = useState<boolean | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const addEmail = async (email: string) => {
    setLoading(true);
    try {
      // Perform API call to add the email
      const response = await addNewsletterMail(email);
      if (response) {
        setSuccess(true);
      }
    } catch (e: any) {
      setError(new Error(e?.message || "An error occurred"));
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return { email, setEmail, success, error, loading, addEmail };
};

export default useAddNewsletterMail;
