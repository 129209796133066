import React from 'react';
import styles from  './Contact.module.css';
import Header from '../../components/Header/Header';
import SectionSeparator from '../../components/SectionSeparator/SectionSeparator';
import Footer from '../../components/Footer/Footer';
import LayoutOneCol from '../../components/LayoutOneCol/LayoutOneCol';
import { TextField, Button, Container } from '@mui/material';
import SectionText from '../../components/SectionText/SectionText';
import ContactForm from '../../components/ContactForm/ContactForm';

const Contact: React.FC = () => {
  return(
    <>
      <Header opacity={1} />   
      <SectionSeparator height='50px'></SectionSeparator>
      <LayoutOneCol 
        id='contact-form'
        align='center'
        width='sm'
        content={
          <>
            <SectionText 
              id='contact-title'
              color='black'
              title='Contact'
              language='fr'
            />
            <ContactForm />
          </>  
        }
      />
      <Footer />
    </>
  );
}

export default Contact;
