import React from 'react';
import { Button, CircularProgress, Paper, List, ListItem, Typography, Alert } from '@mui/material';
import useFetchPriceList from '../../hooks/useFetchPriceList';
import { trackEvent } from '../../analytics';  // Import trackEvent from analytics.js
import { useTheme } from '@mui/material/styles';

interface PriceListProps {
  categoryId: number;
}

const PriceList: React.FC<PriceListProps> = ({ categoryId }) => {
  const { prices, loading, error } = useFetchPriceList(categoryId);
  const theme = useTheme();

  if (loading) return <CircularProgress style={{ color: '#D7A148' }} />;
  if (error) return <Alert severity="error">Error: {error}</Alert>;

  const handleButtonClick = () => {
    trackEvent('button_click', { label: 'Prenez un rendez-vous' });  
  };

  return (
    <Paper elevation={2}
        sx={{
          width: '100%',
          padding: '16px',
          margin: '16px 0',
          borderRadius: '8px',
          backgroundColor: '#fff',
          maxWidth: '500px',
          transition: 'box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out',  
          '&:hover': {
            boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12), 0 7px 10px -5px rgba(0,0,0,0.15)', 
            // backgroundColor: theme.palette.grey[100],  // Optionally change background color on hover
          }
    }}>
      <List>
        {prices.map((price, index) => (
          <ListItem key={index} style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 0',
          }}>
            <Typography variant="body1">{price.serviceName}</Typography>
            <Typography variant="body1" style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
              {price.price}
            </Typography>
          </ListItem>
        ))}
      </List>
      <div style={{ textAlign: 'center', marginTop: '16px' }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#D7A148', color: '#fff' }}
          href="#rendez-vous"
          onClick={handleButtonClick}  
        >
          Prenez un rendez-vous
        </Button>
      </div>
    </Paper>
  );
};

export default PriceList;
